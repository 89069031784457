<!-- eslint-disable no-undef -->
<template>
  <validation-observer ref="simpleRules">
    <div class="pb-1">
      <div class="card">
        <div class="d-lg-flex justify-content-between align-items-center px-2 py-2">
          <h3 class="font-weight-bolder">เรทคูปองพิเศษ</h3>
          <div class="px-2">
            <router-link :to="{ name: 'admin-coupon-rate-add' }"
              class="anan-button--xl-large anan-button anan-button--indigo anan-button--normal ">
              + {{ $t("addCoupon") }}</router-link>
          </div>
        </div>
        <!-- <router-link
          :to="{name: 'admin-coupon-rate-add'}"
          class="px-2"
        > -->
        <b-col md="12" class="ml-2" v-if="itemscoupon.length">
          <div class="card">
            <div class="px-3">
              <div class="row">
                <b-col md="3" class="mr-1" v-for="item in itemscoupon" :key="item">
                  <div class="card p-1 bg-primary position-relative overflow-hidden">
                    <div class="d-flex align-items-center justify-content-between bg-white rounded-lg shadow-lg p-2">
                      <div class="text-center flex-grow-1">
                        <h4 class="text-primary">
                          {{ item.coupon_name }} ({{ item.active ? "เปิดใช้งาน" : "ปิดใช้งาน" }})
                        </h4>
                        <h5 class="text-danger">{{ Commas(item.price) }} ฿</h5>

                        <div class="text-center mt-2">
                          <button class="btn btn-outline-primary btn-sm mr-1" @click="EditDataRate(item)">
                            <feather-icon icon="Edit3Icon" class="mr-1" /> แก้ไข
                          </button>
                          <button class="btn btn-outline-danger btn-sm" @click="DeleteDataRate(item)">
                            <feather-icon icon="TrashIcon" class="mr-1" /> ลบ
                          </button>
                        </div>
                      </div>
                    </div>




                  </div>
                </b-col>
              </div>
            </div>
          </div>
        </b-col>
        <div class="align-items-center px-4 ">
          <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
            <div class="anan-tabs__nav">
              <div class="anan-tabs__nav-warp px-2">
                <div class="anan-tabs__nav-tabs" style="transform: translateX(0px)">
                  <div class="anan-tabs__nav-tab" :class="{ active: isActive === 'all' }" style="white-space: normal"
                    @click="(selected = 0),
                      getData(),
                      (isActive = 'all')
                      ">
                    <span> {{ $t("all") }} </span>
                  </div>

                  <div class="anan-tabs__nav-tab" :class="{ active: isActive === 'cancel' }" style="white-space: normal"
                    @click="(selected = 3),
                      getData(3),
                      (isActive = 'cancel')
                      ">
                    <span> {{ $t("cancel") }} </span>
                  </div>

                  <div class="anan-tabs__nav-tab" :class="{ active: isActive === 'waiting' }"
                    style="white-space: normal" @click="(selected = 1),
                      getData(1),
                      (isActive = 'waiting')
                      ">
                    <span> {{ $t("pendingReview") }} </span>
                  </div>
                  <div class="anan-tabs__nav-tab" :class="{ active: isActive === 'success' }"
                    style="white-space: normal" @click="(selected = 2),
                      getData(2),
                      (isActive = 'success')
                      ">
                    <span> {{ $t("operationCompleted") }} </span>
                  </div>
                </div>
                <div class="income-order-search order-search" style="margin-left: auto; align-self: center;">
                  <!-- align-self: center -->
                  <div class="anan-input search-input" style="max-width: 300px; margin-right: 20px;">
                    <div class="anan-input__inner anan-input__inner--normal">
                      <input v-model="filter" type="text" :placeholder="$t('search')" class="anan-input__input"
                        @keyup.enter="getData()" />
                      <div class="anan-input__suffix">
                        <i class="anan-input__clear-btn anan-icon" />
                        <i class="anan-input__suffix-icon anan-icon">
                          <i class="fal fa-search" @click="getData()" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <b-overlay :show="showOver" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">กรุณารอสักครู่...</p>
            </div>
          </template>
          <div class="pl-2 pr-2 px-5">
            <b-table striped hover responsive :per-page="perPage" :items="items" :fields="fields">
              <template #cell(username)="data">
                <b-link :to="{
                  name: 'admin-employee-edit',
                  params: { id: data.item.uid },
                }">
                  {{ data.item.username }}
                </b-link>
              </template>
              <template #cell(coupon_name)="data">
                <b-link :to="{
                  name: 'admin-coupon-rate-edit',
                  params: { id: data.item.ref_id },
                }">
                  {{ data.item.coupon_name }}
                </b-link>
              </template>

              <template #cell(price)="data">
                {{ Commas(data.item.price) }} ฿
              </template>
              <template #cell(status)="data">
                <span v-if="data.item.statusPay === 2" class="badge badge-success badge-pill">
                  {{ $t("operationCompleted") }}
                </span>
                <span v-else-if="data.item.statusPay === 1" class="badge badge-primary badge-pill">
                  {{ $t("pendingReview") }}
                </span>
                <span v-else class="badge badge-secondary badge-pill">
                  {{ $t('cancelled') }}
                </span>
              </template>

              <template #cell(active)="data">
                <b-form-checkbox v-model="data.item.active" name="is_active" switch inline :value="1"
                  :unchecked-value="0" @change="
                    CheckUpdate(data.item.active, 'active', data.item._id)
                    " />
              </template>

              <template #cell(action)="data">
                <feather-icon icon="XIcon" class="mr-1 text-primary cursor-pointer" v-if="data.item.statusPay === 1"
                  @click="CancelType(data.item)" />

                <feather-icon icon="CheckIcon" class="text-danger cursor-pointer" v-if="data.item.statusPay === 1"
                  @click="ApproveData(data.item)" />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                prev-class="prev-item" next-class="next-item" class="mb-0" @input="getData()">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable,
  BLink,
  BPagination,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import moment from "moment-timezone";

export default {
  components: {
    BTable,
    BLink,
    BPagination,
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {
      selected: 0,
      amount: 0,
      detail: "",
      type: null,
      customerCode: "",
      prefix: "",
      prefixNum: "",

      options: [
        { value: null, text: "ทั้งหมด" },
        { value: 1, text: "รถ" },
        { value: 2, text: "เรือ" },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      totalRow: 0,
      filter: null,
      items: [],
      showOver: false,
      itemscoupon: [],
      isActive: 'all'
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "itemnumber_text",
          label: this.$t("number"),
          thStyle: { width: "10%" },
          thClass: "text-center",
        },
        {
          key: "username",
          label: this.$t("customerCode"),
          sortable: false,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "coupon_name",
          label: this.$t("coupon"),
          sortable: false,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "price",
          label: this.$t("price"),
          sortable: false,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "status",
          label: this.$t("status"),
          sortable: false,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "action",
          label: this.$t("manage"),
          sortable: false,
          thStyle: { width: "5%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
    },
  },
  mounted() {
    this.GetCoupon();
    this.getData(0);
  },
  methods: {
    DeleteDataRate(data) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบข้อมูลใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            };
            this.$http
              .post("/coupon/delete/rate", params)
              .then((response) => {
                if (response.data.status) {
                  this.GetCoupon();
                  this.$bvToast.toast("ลบข้อมูลสำเร็จ", {
                    title: "สำเร็จ",
                    variant: "success",
                    solid: true,
                    autoHideDelay: 1500,
                  });
                } else {
                  this.$bvToast.toast("ลบข้อมูลไม่สำเร็จ", {
                    title: "ไม่สำเร็จ",
                    variant: "error",
                    solid: true,
                    autoHideDelay: 1500,
                  });
                }
              })
              .catch((err) => {
                this.$bvToast.toast("เกิดข้อผิดพลาด", {
                  title: "ไม่สำเร็จ",
                  variant: "error",
                  solid: true,
                  autoHideDelay: 1500,
                });
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    CancelType(data) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการยกเลิกข้อมูลใช่หรือไม่?", {
          title: "ยืนยันการยกเลิกข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            };
            this.$http
              .post("/coupon/cancel/rate", params)
              .then((response) => {
                if (response.data.status) {
                  this.selected = 0
                  this.getData();
                  this.$bvToast.toast("ยกเลิกข้อมูลสำเร็จ", {
                    title: "สำเร็จ",
                    variant: "success",
                    solid: true,
                    autoHideDelay: 1500,
                  });
                } else {
                  this.$bvToast.toast("ยกเลิกข้อมูลไม่สำเร็จ", {
                    title: "ไม่สำเร็จ",
                    variant: "error",
                    solid: true,
                    autoHideDelay: 1500,
                  });
                }
              })
              .catch((err) => {
                this.$bvToast.toast("เกิดข้อผิดพลาด", {
                  title: "ไม่สำเร็จ",
                  variant: "error",
                  solid: true,
                  autoHideDelay: 1500,
                });
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ApproveData(data) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการอนุมัติข้อมูลใช่หรือไม่?", {
          title: "ยืนยันข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            };
            this.$http
              .post("/coupon/Approve/rate", params)
              .then((response) => {
                if (response.data.status) {
                  this.selected = 0
                  this.getData();
                  this.$bvToast.toast("อนุมัติสำเร็จ", {
                    title: "สำเร็จ",
                    variant: "success",
                    solid: true,
                    autoHideDelay: 1500,
                  });
                } else {
                  this.$bvToast.toast("อนุมัติไม่สำเร็จ", {
                    title: "ไม่สำเร็จ",
                    variant: "error",
                    solid: true,
                    autoHideDelay: 1500,
                  });
                }
              })
              .catch((err) => {
                this.$bvToast.toast("เกิดข้อผิดพลาด", {
                  title: "ไม่สำเร็จ",
                  variant: "error",
                  solid: true,
                  autoHideDelay: 1500,
                });
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async GetCoupon() {
      const { data: res } = await this.$http.get("/coupon/rate");
      this.itemscoupon = res.data;
    },
    async getData(type) {
      this.showOver = true;
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          type: this.selected,
          filter: this.filter,
        };
        const { data: res } = await this.$http.get("/coupon/GetPayCoupon", {
          params,
        });
        this.items = res.data;
        this.totalRows = res.total;
        this.showOver = false;
      } catch (e) {
        console.log(e);
        this.showOver = false;
      }
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          };
          this.$http.post("groupbyuser/list/create", params).then(() => {
            this.getData(this.isActive);
            this.$bvToast.toast("เพิ่มข้อมูลสำเร็จ", {
              title: "สำเร็จ",
              variant: "success",
              solid: true,
              autoHideDelay: 1500,
            });
          });
          this.$bvModal.hide("modal-add-new");
        }
      });
    },
    showAddNew() {
      this.$bvModal.show("modal-add-new");
    },
    EditType(data) {
      this.$router.push({
        // eslint-disable-next-line no-underscore-dangle
        name: "admin-coupon-edit",
        params: { id: data._id },
      });
    },
    EditDataRate(data) {
      this.$router.push({
        // eslint-disable-next-line no-underscore-dangle
        name: "admin-coupon-rate-edit",
        params: { id: data._id },
      });
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบข้อมูลใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            };
            this.$http.post("/coupon/coupon_delete", params).then(() => {
              this.getData(this.isActive);
              this.$bvToast.toast("ลบข้อมูลสำเร็จ", {
                title: "สำเร็จ",
                variant: "success",
                solid: true,
                autoHideDelay: 1500,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    CheckUpdate(data, fields, ID) {
      if (data || data === 0) {
        const obj = {
          data,
          fields,
          ID,
        };
        this.$http.post("/coupon/coupon_update_field", obj);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
